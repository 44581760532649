import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './features/user/userSlice';

import entriesReducer from './features/entries/entries';
import adminUsersSlice from './features/adminUsers/adminUsersSlice';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import { setupListeners } from '@reduxjs/toolkit/query';
import schoolReducer from './features/schools/schoolSlice';
import { authenticationApi } from './services/auth';
import { userApi as theUserApi } from './services/user';
import { entriesApi } from './services/entries';
import { locationApi } from './services/location';
import { invitationApi } from './services/invitation';
import { schoolApi } from './services/school';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [ 'user', ],
};

const rootReducer = combineReducers({
	user: userReducer,
	shools: schoolReducer,
	entries: entriesReducer,
	adminUser: adminUsersSlice,
	[ authenticationApi.reducerPath ]: authenticationApi.reducer,
	[ theUserApi.reducerPath ]: theUserApi.reducer,
	[ entriesApi.reducerPath ]: entriesApi.reducer,
	[ locationApi.reducerPath ]: locationApi.reducer,
	[ invitationApi.reducerPath ]: invitationApi.reducer,
	[ schoolApi.reducerPath ]: schoolApi.reducer,

});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	// @ts-ignore
	middleware: (getDefaultMiddleware) => [
		...getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ],
			},
		}).concat(authenticationApi.middleware, theUserApi.middleware, entriesApi.middleware, locationApi.middleware, invitationApi.middleware, schoolApi.middleware),
		thunk,
	],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persitor = persistStore(store);

setupListeners(store.dispatch);
